import {Link, withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default () => (
    <Layout
        title="Detailed explanation of our approach and process"
        description="Prior to a software development process, our clients receive the full explanation of our working methods and conditions."
        ogImage="explanation-en.png"
        translatedPagePath="/nl/voorwaarden/uitgebreide-toelichting/"
        locale={locale}>
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Explanation of our approach and process
                        </h2>
                        <div className="display__number">AP</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Before we start a develop&shy;ment process, our clients receive, along with the proposal and
                            the contract, a <span className="highlight">full explanation</span> of our working
                            procedures and terms and conditions in clear, straight&shy;forward language.
                        </p>
                        <p>
                            This website gives a good overview of{' '}
                            <Link to="/en/approach/" title="Our approach">
                                our approach
                            </Link>{' '}
                            . For more details, you can download below the detailed explanation containing all the
                            information about our approach and process.
                        </p>
                        <p>
                            This publicly available version does not show our rates. If you are interested in working
                            with us, please{' '}
                            <Link to="/en/contact/" title="Contact information Enschede">
                                get in touch
                            </Link>{' '}
                            and we’ll be happy to discuss your plans and answer all your questions.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/approach-process.pdf')}
                            name="Approach & process (PDF)"
                            className="button--download"
                            title="Download our extensive explanation of our services and working method as PDF"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
